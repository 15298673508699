import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import ProductRow from '../../ui/ProductRow/ProductRow';
import MaxWidth from '../../Layout/MaxWidth';

const ProductRowComponentWrapper = styled('div')`
  margin: 3% 0 3%;
  .row-header {
    margin: 1rem 0;
  }
  ${theme.above.lg} {
    margin: 5% 0;
    display: flex;
    align-items: center;
    .row-header {
      width: 20%;
      margin: 0;
      padding-right: 1rem;
      text-align: left;
    }
    .product-row-outer-wrapper {
      width: 80%;
    }
  }
}
`;

const ProductRowComponent = ({ data }) => {
  const props = convertPropsToObject(data);
  const rowProps = {
    id: props.category.value && props.category.value.id,
    limit: props.limit.value,
    orderBy: props.orderBy.value,
    orderByDirection: props.orderByDirection.value,
    slider: props.slider.value === 'yes' ? true : false
  };
  return (
    <MaxWidth>
      <ProductRowComponentWrapper>
        <RowHeader props={props} />
        <ProductRow {...rowProps} />
      </ProductRowComponentWrapper>
    </MaxWidth>
  );
};

export default ProductRowComponent;
