import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import { useSiteContent } from '../../../Util/useSiteContent';

const TopBarUspWrapper = styled('div')`
  > ul {
    > li {
      display: flex;
      align-items: center;
      font-size: ${theme.topBar.usp.fontSize};
      font-weight: ${theme.topBar.usp.fontWeight};
      font-style: italic;
      padding: 0 0.8rem !important;
      svg {
        margin-right: 5px;
      }
      ${theme.only.xs} {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      ${theme.below.md} {
        justify-content: center;
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }
`;

const TopBarUsp = ({ name }) => {
  const items = useSiteContent([name]);
  if (items && items.show)
    return (
      <TopBarUspWrapper className="top-bar-usp-list">
        {items.html}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;
