import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import { config } from '../../shop.config.custom';

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const CategoryPage = props => {
  if (config.routes.category.hide) return null;

  const infinitePagination = true;
  if (infinitePagination) {
    return <LoadableWindowedCategoryPage {...props} />;
  } else {
    return <LoadableStandardCategoryPage {...props} />;
  }
};

export default CategoryPage;
