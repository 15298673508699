import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import { css } from 'linaria';
import React from 'react';
import Selector from './Selector';
import { styled } from 'linaria/react';
import {
  ChannelSelectorButton,
  ChannelSelectorButtonContent
} from './ChannelSelector';
import { theme } from '../../../Theming/Theming';

const SelectorWrapper = styled('div')`
  .channel-list {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      ${theme.above.md} {
        width: 33.33%;
      }
      ${theme.above.lg} {
        width: 25%;
      }
    }
  }
`;

const ChannelSelectorModal = props => {
  return (
    <ChannelContext.Consumer>
      {({ selectedChannel, channels, updateChannel }) => (
        <ModalTrigger
          modalStyles={css`
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: white;
            margin: 1rem;
            outline: none;
            @media screen and (min-width: 900px) {
              left: calc(50% - 450px);
              position: absolute;
              max-width: 900px;
              bottom: initial;
            }
          `}
          target={props => (
            <SelectorWrapper>
              <Selector
                channels={channels}
                selectedChannel={selectedChannel}
                updateChannel={updateChannel}
                className="modal"
                {...props}
              />
            </SelectorWrapper>
          )}
        >
          {({ showTarget }) => (
            <ChannelSelectorButton onClick={showTarget} {...props}>
              <ChannelSelectorButtonContent selectedChannel={selectedChannel} />
            </ChannelSelectorButton>
          )}
        </ModalTrigger>
      )}
    </ChannelContext.Consumer>
  );
};

export default ChannelSelectorModal;
