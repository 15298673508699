export const config = {
  routes: {
    blog: {
      path: '/inspiration',
      id: 150,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: 304
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Hälsokosten',
    siteContentId: 51
  }
};
