import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import FooterContent, { FooterContentWrapper } from './FooterContent';
import NewsletterBox from './NewsletterBox';
import Logo from '../Header/Logo';
import { theme } from '../../Theming/Theming';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0;
    color: ${theme.footer.color};
    ${FooterContentWrapper} {
      padding-top: 0.5rem;
      margin-bottom: 3rem;
    }
  }
`;

const LogoWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background: ${theme.footer.logoWrapper.background};
  > div {
    width: 100%;
    max-width: 400px;
  }
`;

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContent />
          </MaxWidth>
        </div>
      </FooterWrapper>
      <LogoWrapper>
        <div>
          <Logo secondary={true} size={[1, 1, 1, 500]} />
        </div>
      </LogoWrapper>
    </>
  );
};

export default Footer;
