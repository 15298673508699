import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theming/Theming';

export const MaxWidthWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${theme.general.maxWidth};
  padding-left: ${theme.general.pagePadding.md};
  padding-right: ${theme.general.pagePadding.md};
  ${theme.above.md} {
    padding-left: ${theme.general.pagePadding.lg};
    padding-right: ${theme.general.pagePadding.lg};
  }
`;

export const Inner = styled('div')`
  position: relative;
`;

export const PagePaddingWrapper = styled('div')`
  padding: 0 ${theme.general.pagePadding.md};
  ${theme.above.md} {
    padding: 0 ${theme.general.pagePadding.lg};
  }
`;

export const PagePadding = ({ children }) => {
  return (
    <PagePaddingWrapper className="page-padding">{children}</PagePaddingWrapper>
  );
};

const MaxWidth = ({ className = '', children, ...props }) => {
  return (
    <MaxWidthWrapper {...props} className={cx(className, 'max-width-wrapper')}>
      {children}
    </MaxWidthWrapper>
  );
};

export default MaxWidth;
