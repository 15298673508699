import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const NumberBadgeWrapper = styled('span')`
  display: block;
  position: absolute;
  right: -8px;
  top: 0px;
  width: 18px;
  height: 18px;
  font-size: 0.8rem;
  background: ${theme.colors.secondary};
  color: ${theme.colors.font};
  font-weight: ${theme.weights.medium};
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.below.lg} {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  > span {
    display: block;
  }
`;

export const NumberBadge = ({ text }) => {
  return (
    <NumberBadgeWrapper className="number-badge">
      <span>{text}</span>
    </NumberBadgeWrapper>
  );
};
