import { css } from 'linaria';

import reg from './charlotte.woff2';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'charlotte';
        src: url(${reg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
