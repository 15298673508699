import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import CheckIcon from '../ui/icons/CheckIcon';

export const SiteContentHtmlParser = ({ content }) => {
  if (!content) return null;
  const options = {
    replace: domNode => {
      if (domNode && !domNode.attribs) return;
      if (
        domNode.name === 'a' &&
        domNode.attribs.href &&
        domNode.attribs.href.length > 0
      ) {
        const href = domNode.attribs.href;
        if (
          href.search('http') !== -1 ||
          href.search('mailto:') !== -1 ||
          href.search('tel:') !== -1
        ) {
          <a href={domNode.attribs.href} className={domNode.attribs.class}>
            {domToReact(domNode.children, options)}
          </a>;
        } else
          return (
            <Link to={domNode.attribs.href} className={domNode.attribs.class}>
              {domToReact(domNode.children, options)}
            </Link>
          );
      }
      if (
        domNode.name === 'li' &&
        domNode.attribs.class &&
        domNode.attribs.class.search('icon') !== -1
      ) {
        return (
          <li className={domNode.attribs.class}>
            <CheckIcon />
            {domToReact(domNode.children, options)}
          </li>
        );
      }
    }
  };
  return parse(content, options);
};
