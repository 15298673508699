import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import ImageComponent from './ImageComponent';
import { RowHeader } from './RowHeader';
import { contentTextElements } from '../ContentText';

const ImageRowWrapper = styled.div`
  .image-row-children {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      width: 100%;
    }
  }
  [data-columns='1'] > div {
    width: 100%;
    ${theme.below.sm} {
      [data-flight-image-container] {
        padding-bottom: 100% !important;
      }
    }
  }
  [data-columns='2'] > div {
    ${theme.above.md} {
      width: 50%;
    }
  }
  [data-columns='3'] > div {
    ${theme.above.md} {
      width: 33.33333%;
    }
  }
  [data-columns='4'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
  }
  [data-columns='5'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.md} {
      width: 33.33333%;
    }
    ${theme.above.lg} {
      width: 20%;
    }
  }
  [data-columns='6'] > div {
    ${theme.above.sm} {
      width: 50%;
    }
    ${theme.above.lg} {
      width: 33.333%;
    }
  }

  //BACKGROUND
  &.primary,
  &.secondary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
    padding: 2rem 5px 2rem 5px;
    ${theme.above.lg} {
      padding: 3rem 5px 2rem 5px;
    }
    .row-header {
      margin: 0 0 1rem;
    }
    .image-row-inner {
      display: flex;
      flex-direction: column;
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }

  //MARGINS BETWEEN IMAGES
  &.margins {
    .row-header {
      margin-bottom: 1rem;
    }
    .image-row-inner {
      max-width: ${theme.general.maxWidth};
      margin: 0 auto;
      padding: 0 ${theme.general.pagePadding.sm};
      ${theme.below.md} {
        padding: 0;
      }
      .slider-box-wrapper {
        margin: 2% 0 2%;
      }
      .image-row-children {
        padding: 1.5% 0 1.5%;
        > div {
          margin-bottom: 1.5%;
        }
        &[data-columns='2'] > div {
          ${theme.above.md} {
            width: 49%;
          }
        }
        &[data-columns='3'] > div {
          ${theme.above.md} {
            width: 32%;
          }
        }
        &[data-columns='4'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.lg} {
            width: 24%;
          }
        }
        &[data-columns='5'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.md} {
            width: 32%;
          }
          ${theme.above.lg} {
            width: 19%;
          }
        }
        &[data-columns='6'] > div {
          ${theme.above.sm} {
            width: 49%;
          }
          ${theme.above.lg} {
            width: 32%;
          }
        }
      }
    }
  }

  //SLIDER WIDTH
  .slider-items {
    .image-component-wrapper {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
      }
      ${theme.only.md} {
        width: 37%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
`;

const ImageRowChildren = ({ children, aspect }) => (
  <>
    {children.map((child, index) => (
      <ImageComponent
        data={child}
        columns={children.length}
        aspect={aspect}
        key={index}
      />
    ))}
  </>
);

const ImageRow = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const aspect = props.imageAspectRatio.value;
  const margins = props.imageMargins.value;
  const background = props.background.value;
  const items = data.children.length;
  const header = props.header.value;

  //COLUMNS
  const columns = items > 5 ? 5 : items;

  let cssStyles = margins === 'yes' ? 'margins' : '';
  cssStyles += ' ' + background;
  cssStyles += header ? ' has-header' : '';

  if (data && data.children)
    return (
      <ImageRowWrapper
        data-columns={columns}
        className={`image-row ${cssStyles}`}
      >
        <div className="image-row-inner">
          <RowHeader props={props} />
          <div className="image-row-children" data-columns={columns}>
            <ImageRowChildren children={data.children} aspect={aspect} />
          </div>
        </div>
      </ImageRowWrapper>
    );
  else return null;
};

export default ImageRow;
