import React from 'react';
import { styled } from 'linaria/react';
import { PagePadding, Inner } from '../../MaxWidth';
import TopBarUsp from './TopBarUsp';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theming/Theming';
import { Notifications } from '../../Notifications';
import TopBarLinks from './TopBarLinks';

const TopBarWrapper = styled('div')`
  height: ${theme.topBar.height.lg + 'px'};
  transition: height 0.4s ease;
  background: ${theme.topBar.background};
  ${theme.below.md} {
    height: ${theme.topBar.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.topBar.height.scroll + 'px'};
    overflow: hidden;
  }
  .top-nav-list.left {
    display: none;
  }
`;

const ContentWrapper = styled('div')`
  > div > ul {
    display: flex;
    justify-content: center;
    height: ${theme.topBar.height.lg + 'px'};
    ${theme.below.md} {
      height: ${theme.topBar.height.md + 'px'};
    }
    &:not(.top-nav-list) {
      > li {
        padding: 0 0.4rem;
      }
    }
    > li,
    > li > a,
    > li > button,
    > li:before {
      color: ${theme.topBar.color};
      font-size: ${theme.topBar.fontSize};
      font-weight: ${theme.topBar.fontWeight};
    }
    > li {
      display: flex;
      align-items: center;
      svg {
        color: ${theme.topBar.color};
      }
    }
  }
`;

const TopBar = ({ scrolling }) => {
  return (
    <TopBarWrapper data-scrolling={scrolling}>
      <PagePadding>
        <Inner>
          <ContentWrapper>
            <TopBarUsp name={'TopBarUsp'} />
            <Above breakpoint="lg">
              {matches => (matches ? <TopBarLinks /> : null)}
            </Above>
          </ContentWrapper>
        </Inner>
      </PagePadding>
      <Notifications />
    </TopBarWrapper>
  );
};

export default TopBar;
