import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import { styled } from 'linaria/react';

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
`;

const CountryFlag = styled('img')`
  height: 20px;
  margin-right: 5px;
  position: relative;
  top: -2px;
`;

export const ChannelSelectorButton = styled('button')`
  display: flex;
  align-items: center;
`;

export const ChannelSelectorButtonContent = ({ selectedChannel }) => {
  return (
    <>
      <CountryFlag
        src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`}
        alt="Country Flag"
      />
      {selectedChannel.country.name}
    </>
  );
};

export default class HeaderChannelSelector extends Component {
  render() {
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => (
          <Fragment>
            <FlyoutTrigger id="channel-selector">
              {({ showTarget, hideTarget, isOpen }) => (
                <ChannelSelectorButton
                  onClick={isOpen ? hideTarget : showTarget}
                  style={{ background: 'transparent' }}
                >
                  <ChannelSelectorButtonContent
                    selectedChannel={selectedChannel}
                  />
                </ChannelSelectorButton>
              )}
            </FlyoutTrigger>
            <FlyoutTarget id="channel-selector">
              {({ isOpen, hideTarget }) => (
                <Flyout>
                  <Selector
                    channels={channels}
                    hideTarget={hideTarget}
                    selectedChannel={selectedChannel}
                    updateChannel={updateChannel}
                  />
                </Flyout>
              )}
            </FlyoutTarget>
          </Fragment>
        )}
      </ChannelContext.Consumer>
    );
  }
}
